import React, { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Cookie from 'js-cookie'
import { useAuth } from './context/AuthContext'
const UserLayout = lazy(() => import('./layouts/UserLayout'))
const AdminLayout = lazy(() => import('./layouts/AdminLayout'))
const FulfillmentLayout = lazy(() => import('./layouts/FulfillmentLayout'))
const RegisterSupplier = lazy(() =>
  import('./components/Suppliers/RegisterSupplier'),
)
const SupplierBasicDetail = lazy(() =>
  import('./components/Suppliers/SupplierBasicDetail'),
)
const AdminProfile = lazy(() => import('./components/Admin/AdminProfile'))
const AdminDashboard = lazy(() => import('./components/Admin/AdminDashboard'))
const Supplierslogin = lazy(() => import('./components/Login/Supplierlogin'))
const Suppliersregister = lazy(() =>
  import('./components/Login/Supplierregister'),
)
const ProNormAgentRegister = lazy(() =>
  import('./ProNorm/PronormRegister/ProNormRegister'),
)
const ProNormAgent = lazy(() => import('./ProNorm/ProNormUser/ProNormAgent'))
const ProNormSupplier = lazy(() =>
  import('./ProNorm/ProNormUser/ProNormSupplier'),
)
const Supplierstepform = lazy(() =>
  import('./components/Supplierdashbord/Stepform/Supplierstepform'),
)
const EmailVerify = lazy(() => import('./components/EmailVerify/EmailVerify'))
const VerifiedEmail = lazy(() =>
  import('./components/VerificationEmail/VerifiedEmail'),
)
const SuspenseLoader = lazy(() => import('./common/SuspenseLoader'))
const Permissions = lazy(() =>
  import('./components/AccessManagement/Permissions/Permissions'),
)
const Roles = lazy(() => import('./components/AccessManagement/Roles/Roles'))
const RolesAdd = lazy(() =>
  import('./components/AccessManagement/Roles/RolesAdd'),
)
const RolesEdit = lazy(() =>
  import('./components/AccessManagement/Roles/RolesAdd'),
)
const Users = lazy(() => import('./components/AccessManagement/Users/Users'))
const UsersAdd = lazy(() =>
  import('./components/AccessManagement/Users/UsersAdd'),
)
const Suppliers = lazy(() => import('./components/Suppliers/Suppliers'))
const EditSupplier = lazy(() => import('./components/Suppliers/EditSupplier'))
const Affiliate = lazy(() => import('./components/Affiliate/Affiliate'))
const AddAffiliate = lazy(() => import('./components/Affiliate/AddAffiliate'))

const Tickets = lazy(() => import('./components/Tickets/Tickets'))
const TicketsAdd = lazy(() => import('./components/Tickets/TicketsAdd'))
const Ticketsviews = lazy(() => import('./components/Tickets/View'))

const TicketCategory = lazy(() =>
  import('./components/TicketCategory/TicketCategory'),
)
const AddEditTicketCategories = lazy(() =>
  import('./components/TicketCategory/AddEditTicketCategories'),
)
const TicketPriority = lazy(() =>
  import('./components/TicketPriority/TicketPriority'),
)

const AddEditTicketPriorities = lazy(() =>
  import('./components/TicketPriority/AddEditTicketPriorities'),
)
const Marketplace = lazy(() => import('./components/Marketplace/Marketplace'))
const MarketplaceAdd = lazy(() =>
  import('./components/Marketplace/MarketplaceAdd'),
)
const WarehouseInventory = lazy(() =>
  import('./components/WarehouseInventory/WarehouseInventory'),
)
const ShippingCarriers = lazy(() =>
  import('./components/ShippingCarriers/ShippingCarriers'),
)
const ShippingCarriersAdd = lazy(() =>
  import('./components/ShippingCarriers/ShippingCarriersAdd'),
)
const CarrierClass = lazy(() =>
  import('./components/CarrierClass/CarrierClass'),
)
const CarrierClassAdd = lazy(() =>
  import('./components/CarrierClass/CarrierClassAdd'),
)
const Brands = lazy(() => import('./components/Brands/Brands'))
const Category = lazy(() => import('./components/Category/Category'))
const Country = lazy(() => import('./components/Country/Country'))
const Territory = lazy(() => import('./components/Territory/Territory'))
const State = lazy(() => import('./components/State/State'))
const Cities = lazy(() => import('./components/Cities/Cities'))
const Currency = lazy(() => import('./components/Currency/Currency'))

const Fulfillment = lazy(() =>
  import('./components/AccessManagement/Fulfillment/User'),
)

const AdminFulFillments = lazy(() =>
  import('./components/AdminFulFillment/AdminFulFillments'),
)
const FulfillmentAdd = lazy(() =>
  import('./components/AccessManagement/Fulfillment/UsersAdd'),
)
const ShippingServices = lazy(() =>
  import('./components/ShippingServices/ShippingServices'),
)
const ShippingServicesAdd = lazy(() =>
  import('./components/ShippingServices/ShippingServicesAdd'),
)
const ProNormUser = lazy(() => import('./ProNorm/ProNormUser/ProNormUser'))
const Products = lazy(() => import('./components/Products/Products'))
const ImportFile = lazy(() =>
  import('./components/Supplierdashbord/ImportFile'),
)
const AddProduct = lazy(() => import('./components/Products/AddProduct'))
const Orders = lazy(() => import('./components/Orders/Orders'))
const ManageOrders = lazy(() => import('./components/Orders/ManageOrders'))
const PendingOrder = lazy(() => import('./components/Orders/pendingOrder'))
const ShippingOrder = lazy(() => import('./components/Orders/ShippingOrder'))
const UnShippedOrder = lazy(() => import('./components/Orders/unShippedOrder'))
const CancelledOrder = lazy(() => import('./components/Orders/CancelledOrder'))
const SentOrder = lazy(() => import('./components/Orders/SentOrder'))
const OrdersDetail = lazy(() => import('./components/Orders/OrdersDetail'))
const ProductDetail = lazy(() => import('./components/Orders/ProductDetail'))
const Warehouses = lazy(() => import('./components/Warehouses/Warehouses'))
const CreateWareHouse = lazy(() =>
  import('./components/Supplierdashbord/CreateWareHouse'),
)
const Suppliersdashbord = lazy(() =>
  import('./components/Supplierdashbord/SuppliersDashboard'),
)
const AddEditTerritory = lazy(() =>
  import('./components/Territory/AddEditTerritory'),
)
const Suppliersproducts = lazy(() =>
  import('./components/Supplierdashbord/Product'),
)
const Productimage = lazy(() =>
  import('./components/Supplierdashbord/Productimage'),
)
const Sellnormai = lazy(() =>
  import('./components/Supplierdashbord/Sellnormai'),
)
const Productdetails = lazy(() =>
  import('./components/Supplierdashbord/Productdetails'),
)
const Productedit = lazy(() =>
  import('./components/Supplierdashbord/Productedit'),
)
const Warehousess = lazy(() =>
  import('./components/Supplierdashbord/SupplierWarehouses'),
)
const WarehousessEdit = lazy(() =>
  import('./components/Supplierdashbord/SupplierWarehousesEdit'),
)
const Profile = lazy(() => import('./components/Supplierdashbord/Profile'))

// const Statement = lazy(() =>
//   import('./components/Supplierdashbord/Payments/Statement'),
// )

const Inventory = lazy(() => import('./components/Supplierdashbord/Inventory'))
const Territories_Retialers = lazy(() =>
  import('./components/Supplierdashbord/TerritoriesRetialers'),
)
const WarehousesAdd = lazy(() =>
  import('./components/Warehouses/WarehousesAdd'),
)

const FulFillments = lazy(() =>
  import('./components/Supplierdashbord/FulFillments'),
)

const CurrentSales = lazy(() =>
  import('./components/Supplierdashbord/Payments/CurrentSales'),
)
const PastPayout = lazy(() =>
  import('./components/Supplierdashbord/Payments/PastPayout'),
)
const NextPayout = lazy(() =>
  import('./components/Supplierdashbord/Payments/NextPayout'),
)
const Wallet = lazy(() =>
  import('./components/Supplierdashbord/Payments/Wallet'),
)
const Statements = lazy(() =>
  import('./components/Supplierdashbord/Payments/Statements'),
)
const Integrations = lazy(() =>
  import('./components/Integrations/Integrations'),
)
const AddEditIntegration = lazy(() =>
  import('./components/Integrations/AddEditIntegration'),
)
const Promotions = lazy(() => import('./components/Promotions/Promotions'))
const AddEditPromotion = lazy(() =>
  import('./components/Promotions/AddEditPromotion'),
)
const MarketPlaceInventory = lazy(() =>
  import('./components/MarketPlaceInventory/MarketPlaceInventory'),
)
const AddEditMarketPlaceInventory = lazy(() =>
  import('./components/MarketPlaceInventory/AddEditMarketPlaceInventory'),
)
const Support = lazy(() =>
  import('./components/Supplierdashbord/Support/Support'),
)
const Supportview = lazy(() =>
  import('./components/Supplierdashbord/Support/View'),
)
const AddEditTicket = lazy(() =>
  import('./components/Supplierdashbord/Support/AddEditTicket'),
)
const ForgetPassword = lazy(() =>
  import('./components/ForgetPassword/ForgetPassword'),
)
const ResetPassword = lazy(() =>
  import('./components/ResetPassword/ResetPassword'),
)
const Notification = lazy(() => import('./components/Notification'))

const ProtectedRoute = ({ element, role }) => {
  const { user, isImpersonating } = useAuth()
  return user?.user_type === role || isImpersonating ? (
    element
  ) : (
    <Navigate to="/login" />
  )
}
const SupplierStepRoute = ({ element }) => {
  const supplierId = localStorage.getItem('supplier_id')
  return supplierId ? element : <Navigate to="/login" />
}
function AppRoutes() {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Routes>
        {/* Admin Routes */}
        <Route
          path="/*"
          element={<ProtectedRoute role="admin" element={<AdminLayout />} />}
        >
          <Route exact path="adminprofile" element={<AdminProfile />} />
          <Route path="brands" element={<Brands />} />
          <Route exact path="" element={<AdminDashboard />} />
          <Route exact path="home" element={<AdminDashboard />} />
          <Route exact path="permissions" element={<Permissions />} />
          <Route exact path="roles" element={<Roles />} />
          <Route exact path="roles/add" element={<RolesAdd />} />
          <Route exact path="roles/edit/:id" element={<RolesEdit />} />
          <Route exact path="users" element={<Users />} />
          <Route exact path="users/add" element={<UsersAdd />} />
          <Route exact path="users/edit/:id" element={<UsersAdd />} />
          <Route exact path="fulfillment-users" element={<Fulfillment />} />
          <Route
            exact
            path="fulfillment-users/add"
            element={<FulfillmentAdd />}
          />
          <Route
            exact
            path="fulfillment-users/edit/:id"
            element={<FulfillmentAdd />}
          />
          <Route exact path="suppliers/:status" element={<Suppliers />} />
          <Route exact path="suppliers/add" element={<EditSupplier />} />
          <Route exact path="suppliers/edit/:id" element={<EditSupplier />} />
          <Route exact path="affiliate" element={<Affiliate />} />
          <Route exact path="affiliate/add" element={<AddAffiliate />} />
          <Route exact path="affiliate/edit/:id" element={<AddAffiliate />} />
          <Route exact path="ticket-priority" element={<TicketPriority />} />
          <Route exact path="tickets" element={<Tickets />} />
          <Route exact path="tickets/view/:id" element={<Ticketsviews />} />
          <Route exact path="tickets/add" element={<TicketsAdd />} />
          <Route exact path="tickets/edit/:id" element={<TicketsAdd />} />
          <Route
            exact
            path="ticket-priority/add"
            element={<AddEditTicketPriorities />}
          />
          <Route
            exact
            path="ticket-priority/edit/:id"
            element={<AddEditTicketPriorities />}
          />
          <Route exact path="ticket-category" element={<TicketCategory />} />
          <Route
            exact
            path="ticket-category/add"
            element={<AddEditTicketCategories />}
          />
          <Route
            exact
            path="ticket-category/edit/:id"
            element={<AddEditTicketCategories />}
          />
          <Route exact path="marketplace" element={<Marketplace />} />
          <Route exact path="marketplace/add" element={<MarketplaceAdd />} />
          <Route
            exact
            path="marketplace/edit/:id"
            element={<MarketplaceAdd />}
          />
          <Route
            exact
            path="shipping-carriers"
            element={<ShippingCarriers />}
          />
          <Route
            exact
            path="shipping-carriers/add"
            element={<ShippingCarriersAdd />}
          />
          <Route
            exact
            path="shipping-carriers/edit/:id"
            element={<ShippingCarriersAdd />}
          />
          <Route exact path="carrier-class" element={<CarrierClass />} />
          <Route exact path="carrier-class/add" element={<CarrierClassAdd />} />
          <Route
            exact
            path="carrier-class/edit/:id"
            element={<CarrierClassAdd />}
          />
          <Route exact path="territory/create" element={<AddEditTerritory />} />
          <Route path="territory/update/:id" element={<AddEditTerritory />} />
          <Route
            exact
            path="shipping-services"
            element={<ShippingServices />}
          />
          <Route
            exact
            path="shipping-services/add"
            element={<ShippingServicesAdd />}
          />
          <Route
            exact
            path="shipping-services/edit/:id"
            element={<ShippingServicesAdd />}
          />

          <Route exact path="notification" element={<Notification />} />
          <Route exact path="products" element={<Products />} />
          <Route exact path="product/add" element={<AddProduct />} />
          <Route exact path="product/edit/:id" element={<AddProduct />} />
          <Route exact path="category" element={<Category />} />
          <Route exact path="country" element={<Country />} />
          <Route exact path="territory" element={<Territory />} />
          <Route exact path="state" element={<State />} />
          <Route exact path="Cities" element={<Cities />} />
          <Route exact path="currency" element={<Currency />} />
          <Route exact path="fulfillments" element={<AdminFulFillments />} />
          <Route exact path="warehouses" element={<Warehouses />} />
          <Route exact path="warehouses/add" element={<WarehousesAdd />} />
          {/* <Route exact path="warehouses/add" element={<CreateWareHouse />} /> */}
          <Route exact path="integrations" element={<Integrations />} />
          <Route
            exact
            path="integrations/add"
            element={<AddEditIntegration />}
          />
          <Route
            exact
            path="integrations/edit/:id"
            element={<AddEditIntegration />}
          />
          <Route exact path="promotions" element={<Promotions />} />
          <Route exact path="promotions/add" element={<AddEditPromotion />} />
          <Route
            exact
            path="promotions/edit/:id"
            element={<AddEditPromotion />}
          />
          <Route
            exact
            path="marketplace-inventory"
            element={<MarketPlaceInventory />}
          />
          <Route
            exact
            path="marketplace-inventory/add"
            element={<AddEditMarketPlaceInventory />}
          />
          <Route
            exact
            path="marketplace-inventory/edit/:id"
            element={<AddEditMarketPlaceInventory />}
          />
          {/* <Route exact path="warehouses" element={<Warehouses />} /> */}
          <Route
            exact
            path="warehouse-inventory"
            element={<WarehouseInventory />}
          />
          <Route exact path="orders" element={<Orders />} />
          <Route exact path="manageorders" element={<ManageOrders />} />
          <Route exact path="orders/pending" element={<PendingOrder />} />
          <Route
            exact
            path="orders/shippinglabel/:id"
            element={<ShippingOrder />}
          />
          <Route exact path="orders/unshipped" element={<UnShippedOrder />} />
          <Route exact path="orders/cancelled" element={<CancelledOrder />} />
          <Route exact path="orders/sent" element={<SentOrder />} />
          <Route path="pronorm" element={<ProNormUser />} />
          <Route path="pronorm-agent" element={<ProNormAgent />} />
          <Route path="proagent-supplier/:id" element={<ProNormSupplier />} />
          <Route path="proagent-supplier" element={<ProNormSupplier />} />
          <Route exact path="orders/:order_id" element={<OrdersDetail />} />
          <Route
            exact
            path="editproductdetail/:id"
            element={<ProductDetail />}
          />
        </Route>

        {/* Supplier Routes */}
        <Route
          path="/supplier/*"
          element={<ProtectedRoute role="supplier" element={<UserLayout />} />}
        >
          <Route exact path="" element={<Suppliersdashbord />} />
          <Route exact path="dashboard" element={<Suppliersdashbord />} />
          <Route exact path="products" element={<Suppliersproducts />} />
          <Route exact path="importfile" element={<ImportFile />} />
          <Route exact path="productimage" element={<Productimage />} />
          <Route
            exact
            path="product-image/sellnorm-ai"
            element={<Sellnormai />}
          />
          <Route exact path="support" element={<Support />} />
          <Route
            exact
            path="support/supportview/:id"
            element={<Supportview />}
          />
          <Route exact path="support/addticket" element={<AddEditTicket />} />
          <Route
            exact
            path="support/editticket/:id"
            element={<AddEditTicket />}
          />
          <Route
            exact
            path="fulfillment/orders/shippinglabel/:id"
            element={<ShippingOrder />}
          />
          <Route
            exact
            path="fulfillment/orders/:order_id"
            element={<OrdersDetail />}
          />
          <Route exact path="productdetails" element={<Productdetails />} />
          <Route exact path="productedit/:id" element={<Productedit />} />
          <Route exact path="fulfillment" element={<FulFillments />} />
          <Route exact path="currentSales" element={<CurrentSales />} />
          <Route exact path="pastpayout" element={<PastPayout />} />
          <Route exact path="nextpayout" element={<NextPayout />} />
          <Route exact path="wallet" element={<Wallet />} />
          <Route exact path="statements" element={<Statements />} />
          <Route exact path="warehouses" element={<Warehousess />} />
          <Route exact path="create-warehouse" element={<CreateWareHouse />} />
          <Route exact path="editwarehouse/:id" element={<CreateWareHouse />} />
          <Route exact path="profile" element={<Profile />} />
          <Route exact path="warehousesedit" element={<WarehousessEdit />} />
          <Route exact path="inventory" element={<Inventory />} />
          <Route
            exact
            path="Territories-Retialers"
            element={<Territories_Retialers />}
          />
        </Route>

        <Route
          path="/fulfillment/*"
          element={
            <ProtectedRoute role="fulfilment" element={<FulfillmentLayout />} />
          }
        >
          <Route exact path="" element={<FulFillments />} />
          <Route
            exact
            path="orders/shippinglabel/:id"
            element={<ShippingOrder />}
          />
          <Route exact path="orders/:order_id" element={<OrdersDetail />} />
        </Route>
        {/* Default redirect */}
        <Route path="/register" element={<RegisterSupplier />} />
        <Route path="/pronorm-register" element={<ProNormAgentRegister />} />
        <Route path="/supplierbasicinfo" element={<SupplierBasicDetail />} />
        {/* <Route path="/" element={<Navigate to="/login" />} /> */}
        <Route path="/login" element={<Supplierslogin />} />
        <Route path="/supplier/register" element={<Suppliersregister />} />
        <Route
          path="/supplier/on-boarding"
          element={<SupplierStepRoute element={<Supplierstepform />} />}
        />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/verify-email/:token" element={<EmailVerify />} />
        <Route path="/verificationemail" element={<VerifiedEmail />} />
      </Routes>
    </Suspense>
  )
}

function wait(time) {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}

export default AppRoutes
